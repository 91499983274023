var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"UserForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"Header"},[_c('div',{staticClass:"LeftSide"},[_c('UserIcon'),_c('h2',[_vm._v(_vm._s(_vm.isNewUser ? _vm.mixWB('CREATE_USER') : _vm.user.fullName))])],1),_c('div',{staticClass:"RightSide"},[(_vm.currentUser.isAdmin || _vm.currentUser.isSuperUser)?_c('ToggleButton',{attrs:{"groupName":"isActive","value":"isActive","text":_vm.mixWB('IS_ACTIVE'),"isSelected":_vm.data.isActive || false},on:{"check":_vm.onToggleIsActive}}):_vm._e()],1)]),(!_vm.isNewUser)?_c('span',{staticClass:"LastSeen"},[_vm._v(" "+_vm._s(_vm.mixWB('LAST_SEEN'))+": "+_vm._s(_vm.user.lastSeen ? _vm.mixGetDate(_vm.user.lastSeen) : _vm.mixWB('NEVER'))+" ")]):_vm._e(),(_vm.error)?_c('ErrorText',{attrs:{"text":_vm.error}}):_vm._e(),_c('InputField',{attrs:{"formGroupName":"user-form","value":_vm.data.fullName,"label":_vm.mixWB('FULL_NAME'),"name":"fullName","isRequired":true,"disabled":!_vm.canEdit || _vm.isSaving},on:{"on-update":_vm.onUpdate}}),_c('InputField',{attrs:{"formGroupName":"user-form","value":_vm.data.initials,"label":_vm.mixWB('INITIALS'),"name":"initials","isRequired":true,"disabled":!_vm.canEdit || _vm.isSaving},on:{"on-update":_vm.onUpdate}}),_c('InputField',{attrs:{"formGroupName":"user-form","value":_vm.data.email,"label":_vm.mixWB('EMAIL'),"name":"email","isRequired":true,"disabled":!_vm.canEditEmail || _vm.isSaving},on:{"on-update":_vm.onUpdate}}),(!_vm.isDemoUser)?_c('InputField',{attrs:{"formGroupName":"user-form","value":_vm.data.phoneNumber,"label":_vm.mixWB('PHONE_NUMBER'),"name":"phoneNumber","isRequired":true,"disabled":!_vm.canEdit || _vm.isSaving},on:{"on-update":_vm.onUpdate}}):_vm._e(),(
      (_vm.currentUser.isAdmin || _vm.currentUser.isSuperUser)
        && _vm.currentAccount.id === 'fsw2qcnTnElz0Wd546be'
    )?_c('InputField',{attrs:{"formGroupName":"user-form","type":"date","value":_vm.data.expiryDate,"label":_vm.mixWB('EXPIRATION_DATE'),"name":"expiryDate","disabled":_vm.isSaving},on:{"on-update":_vm.onUpdate}}):_vm._e(),(!_vm.isDemoUser && (_vm.currentUser.isAdmin || _vm.currentUser.isSuperUser))?_c('div',{staticClass:"Roles"},[_c('h3',[_vm._v(_vm._s(_vm.mixWB('ROLES')))]),_c('Checkbox',{attrs:{"name":_vm.roles.ROLE_SCREENER,"isSelected":_vm.data.roles.includes(_vm.roles.ROLE_SCREENER),"text":_vm.mixWB('ROLE_SCREENER')},on:{"checkbox-click":_vm.onRoleChange}}),_c('Checkbox',{attrs:{"name":_vm.roles.ROLE_SUPER_USER,"isSelected":_vm.data.roles.includes(_vm.roles.ROLE_SUPER_USER),"text":_vm.mixWB('ROLE_SUPER_USER')},on:{"checkbox-click":_vm.onRoleChange}})],1):_vm._e(),(_vm.canEdit)?_c('Button',{attrs:{"isLoading":_vm.isSaving,"text":_vm.isNewUser ? _vm.mixWB('CREATE_USER') : _vm.mixWB('SAVE'),"type":"submit"}}):_vm._e(),(_vm.user && _vm.currentUser.id == _vm.user.id)?_c('Accordion',{attrs:{"title":_vm.mixWB('CHANGE_PASSWORD'),"bodyComponent":_vm.ChangePasswordForm,"bodyComponentProps":{
      allwaysUseWithCurrentPassword: true
    },"isOpenOnLoad":false}}):_vm._e(),(_vm.user && _vm.user.overallStats && _vm.user.roles.some(function (x){ return ['screener', 'admin'].includes(x); }))?_c('Accordion',{attrs:{"title":_vm.mixWB('STAT_TABLE_TITLE'),"bodyComponent":_vm.Stat,"bodyComponentProps":{
      stats: _vm.user
    },"padding":'none'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }